body {
	font-family: "Montserrat";
}

.bg-navBackground {
	background-image: linear-gradient(90deg, rgba(250, 0, 118, 1.0), rgba(60, 8, 118, 1.0));
}

.brand {
	font-family: "Montserrat";
	font-size: 40px;
	color: white;
	text-decoration: none;
}

::-moz-selection {
	background-color: #988fff;
	color: #fff
}

::selection {
	background-color: #988fff;
	color: #fff
}

.controlled-carousel {
	background-image: linear-gradient(90deg, rgba(250, 0, 118, 1.0), rgba(60, 8, 118, 1.0));
	height: 250px;
}

.home-section {
	background-image: linear-gradient(90deg, rgba(250, 0, 118, 1.0), rgba(60, 8, 118, 1.0));
	color: white;
	padding: 2%;
	padding-bottom: 5%;
}

.home-section h1 {
	padding: 12px;
}

.home-section img {
	height: 120px;
}


.header-btn {
	background-color: #fff;
	border-radius: 0;
	padding-left: 30px;
	padding-right: 30px;
	border: 1px solid transparent;
	color: #222
}

.header-btn:hover {
	border: 1px solid #fff;
	background: transparent;
	color: #fff
}


.stamp-section {
	background-image: linear-gradient(0deg, #888fff 0%, #ce90ff 100%);
	color: white;
	padding: 10%;
}

.stamp-section a {
	color: white;
}

.stamp-section h1 {}

.stamp-section ol {
	margin-top: 1rem;
}

.empty-space {
	height: 250px;
}


.work-section {
	background-color: white;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 10%;

}

.about-section {
	background-color: white;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 10%;

}

.work-section h1 {
	padding-top: 5%;
	padding-bottom: 5%;

}

.single-service h4 {
	padding: 20px 0;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	font-size: 17px
}

.single-service:hover {
	cursor: pointer
}

.single-service:hover h4 {
	color: #988fff
}

.single-service .thumb {
	overflow: hidden
}

.single-service .thumb img {
	width: 100%;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s
}

.single-service:hover .thumb img {
	transform: scale(1.1) rotate(-2deg)
}

@media (max-width: 960px) {
	.single-service {
		text-align: center;
		margin-bottom: 30px
	}
}

.about-section {
	background: #04091e;
	padding-top: 100px
}

.about-section h6 {
	text-transform: uppercase;
	color: #fff;
	margin-bottom: 25px;
	font-size: 16px;
	font-weight: 600
}


.single-footer-widget ::selection {
	background-color: #988fff;
	color: #fff
}

.single-footer-widget p {
	color: #777
}


@media (max-width: 991px) {
	.work-process-area .justify-content-around {
		justify-content: center !important
	}
}

.work-process-area {
	padding-bottom: 2%;
}

.work-process-area .total-work-process {
	padding: 0 30px;
}

@media (max-width: 991.98px) {
	.work-process-area .total-work-process {
		padding: 0
	}
}

.work-process-area .single-work-process {
	margin-top: 30px;
	text-align: center
}

@media (max-width: 767.98px) {
	.work-process-area .single-work-process {
		width: 100%
	}
}

.work-process-area .single-work-process .work-icon-box {
	display: inline-block;
	width: 120px;
	height: 120px;
	border-radius: 3px;
	border: 1px solid #eee;
	text-align: center
}

.work-process-area .single-work-process .work-icon-box span {
	line-height: 118px;
	font-size: 42px
}

.work-process-area .single-work-process .caption {
	font-size: 16px;
	margin-top: 20px
}

.work-process-area .work-arrow {
	margin-top: -30px
}

.work-process-area h1 {
	padding: 7%
}

@media (max-width: 1199.98px) {
	.work-process-area .work-arrow img {
		width: 40px;
		height: auto
	}
}

@media (max-width: 991.98px) {
	.work-process-area .work-arrow img {
		width: 25px;
		height: auto
	}
}

@media (max-width: 767.98px) {
	.work-process-area .work-arrow {
		display: none
	}
}


.map {
	width: 100%;
	height: 350px;
}

@media (max-width: 767px) {
	.map {
		height: 200px;
	}
}

ul {
	list-style: none
}

a,
a:focus,
a:hover {
	text-decoration: none !important;
	outline: 0
}